/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) { 

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages


        (function loadSite(){

          // number of loaded images for preloader progress
          var body = $('body');
          var loadedCount = 0; //current number of images loaded
          var imagesToLoad = $('.js-img-load').length; //number of slides with .bcg container
          var loadingProgress = 0; //timeline progress - starts at 0
           
          $('.barba-container').imagesLoaded({
              //background: true
          }).progress( function( instance, image ) { 
              loadProgress();
          });
           
          function loadProgress(imgLoad, image)
          {
            //one more image has been loaded
            loadedCount++;
         
            loadingProgress = (loadedCount/imagesToLoad);
         
            // GSAP tween of our progress bar timeline
            TweenLite.to(progressTl, 0.7, {progress:loadingProgress, ease:Linear.easeNone});
           
          }

          //progress timeline
          var progressTl = new TimelineMax({
              paused: true,
              onUpdate: progressUpdate,
              onComplete: loadComplete
          });
           
          progressTl
            //tween the progress bar width
            .set(body, {className: '+=is-loading'}).to($('.loader-progress'), 1, {css:{width:"100%"}, ease:Linear.easeNone});
           
          //as the progress bar width updates and grows we put the percentage loaded in the screen
          function progressUpdate() {
            //the percentage loaded based on the tween's progress
            loadingProgress = Math.round(progressTl.progress() * 100);
         
            //we put the percentage in the screen
            $(".loader-text").text(loadingProgress + '%');
           
          }

          function loadComplete() {
            // preloader out
            var wrapper = $('.loader-wrapper');
            var body = $('body');
            var html = $('html');
            var loader = $('#site-loader');
            var intro = $('#site-intro');
            var intro_left = $('.js-intro-left');
            var intro_right = $('.js-intro-right');
            var hero = $('.js-hero');            
            var logo = $('.js-logo');
            var title = $('.js-title');
            var hint = $('.js-scroll-hint');
            var preloaderOutTl = new TimelineMax();
         
            preloaderOutTl
              .to(wrapper, 0.3, {autoAlpha: 0, ease:Back.easeInOut})
              .set(html, {className: '+=is-intro-enter'})
              .set(body, {className: '-=is-loading'})
              .set(html, {className: '+=is-intro-leave'})
              .to(loader, 0.8, {yPercent: 100, ease:Power4.easeInOut})
              .set(loader, {className: '+=is-hidden'})
              .set(html, {className: '+=is-intro-hide'})
              .set(intro, {className: '+=is-hidden'})
              .fromTo(title, 0.8, {autoAlpha: 0}, {autoAlpha:1, ease:Power1.easeOut}, '+=0.4')
              .from(hint, 0.3, {y: -20, autoAlpha: 0, ease:Power1.easeOut}, '+=0.1')
              .set(hero, {className: '+=is-loaded'});
         
            return preloaderOutTl;
          }

        })();


        (function setMenuSize(){

          var accordion = $('.js-accordion');
          var menu_width = $('.js-accordion').width();
          menu_width = menu_width + "px";
          accordion.css("width",menu_width);

          var menu_height = accordion.height();
          menu_height = menu_height + "px";
          accordion.css("height",menu_height);

          var resize = function() {
            accordion.css({
                width: accordion.width()
            });
            accordion.css({
                height: accordion.height()
            });         
          };

          $(window).on('resize', resize);
          resize();

        })();


        (function affixMenuNav(){

          $(document).ready(function() {
            // grab the initial top offset of the navigation 
            var stickyNavTop = $('.js-accordion').offset().top; 
            
            // our function that decides weather the navigation bar should have "fixed" css position or not.
            var stickyNav = function(){
            var scrollTop = $(window).scrollTop(); // our current vertical position from the top
                   
              // if we've scrolled more than the navigation, change its position to fixed to stick to top,
              // otherwise change it back to relative
              if (scrollTop > stickyNavTop) { 
                  $('.js-accordion').addClass('is-sticky');
              } else {
                  $('.js-accordion').removeClass('is-sticky'); 
              }

            };

            stickyNav();
            // and run it again every time you scroll
            $(window).scroll(function() {
              stickyNav();
            });
          });

        })();


        (function removeStickyNav(){

          $(document).ready(function() {

            // our function that decides weather the navigation bar should have "fixed" css position or not.
            var unstickyNav = function(){              

              var $accordion = $('.js-accordion');
              var $featureBlocksTop = $('.js-feature-blocks').offset().top; 
              var $menu_height = $('.js-accordion').height();
              var $scrollTop = $(window).scrollTop(); // our current vertical position from the top
              var $distance = ($featureBlocksTop - $scrollTop); 

              // function step_1() {
              //   $accordion.toggleClass('is-sticky');
              // }

              // var unstickTl = new TimelineMax({
              //     paused: true
              // });

              // unstickTl.fromTo($accordion, 0.1,  
              //   { 
              //     opacity:1
              //   }, {
              //     opacity:0,
              //     onComplete: step_1
              //   });  


              // if we've scrolled more than the navigation, change its position to fixed to stick to top,
              // otherwise change it back to relative
              if ($distance <= $menu_height) { 
                  $accordion.removeClass('is-sticky');
              } 

            };

            unstickyNav();
            // and run it again every time you scroll
            $(window).scroll(function() {
              unstickyNav();
            });
          });

        })();


        (function resizeHeroContent(){

          $(document).ready(function() {

            if($(window).width() > 768) {

              var column_height = $("html").height();
              var column_height = column_height - 0; // 80 is the header height
              var column_height = column_height + "px";

              $(".js-hero").css("height",column_height);
              $(".js-header").css("height",column_height);

              var resize = function() {
                $('.js-hero', '.js-header').css({
                    height: $(window).height()
                });
              };

              $(window).on('resize', resize);
              resize();

            }

          });

        })();


        (function scrollToMenu() {

          var $menu = $(".js-scroll-menu");

          $menu.on("click","a", function(){
            var $this = $(this),
            href = $this.attr("href"),
            topY = $(href).offset().top;

            TweenLite.to($(window), 3, {
              scrollTo:{
                y: topY,
                autoKill: false
              },
              ease:Power3.easeOut
            });

            return false;
          });

        })();  


        (function scrollReveal() {

          $('[data-scrollreveal]').each(function (index, elem) {
            // Init ScrollMagic Controller
            var controller = new ScrollMagic.Controller();
             
            // Create Animations
            var img = $(elem).find('.js-feature-image'),
                largeImg = $(elem).find('.js-feature-large-image'),
                caption = $(elem).find('.js-feature-caption'),
                title = $(elem).find('.js-feature-header'),
                hr = $(elem).find('hr'),
                text = $(elem).find('.js-feature-text'),
                slider = $(elem).find('.js-feature-slider'),
                gallery = $(elem).find('.js-feature-gallery'),
                btn = $(elem).find('.js-feature-link');
            
            var tl = new TimelineMax({pause: true}); 

            tl.add("start") // add timeline label
              .fromTo(title, 0.6, { x: '-140px', opacity: 0 }, { x: 0, opacity: 1, ease: Power4.easeInOut }, "end")
              .fromTo(hr, 0.6, { x: '140px', opacity: 0 }, { x: 0, opacity: 1, ease: Power2.easeInOut }, "end")
              .fromTo(text, 0.6, { x: '140px', opacity: 0 }, { x: 0, opacity: 1, ease: Power4.easeInOut }, "end")
              .fromTo(btn, 0.6, { x: '-140px', opacity: 0 }, { x: 0, opacity: 1, ease: Power4.easeInOut }, "end")
              .fromTo(img, 0.6, { y: '140px', opacity: 0 }, { y: 0, opacity: 1, ease: Power2.easeInOut }, "end")
              .fromTo(gallery, 0.6, { y: '240px', opacity: 0 }, { y: 0, opacity: 1, ease: Power2.easeInOut }, "end")
              .fromTo(largeImg, 0.6, { y: '240px', opacity: 0 }, { y: 0, opacity: 1, ease: Power2.easeInOut }, "end")
              .staggerFromTo(slider, 0.6, { y: '140px', opacity: 0 }, { y: 0, opacity: 1, ease: Power2.easeInOut }, "end")
              .staggerFromTo(caption, 0.6, { y: '-140px', opacity: 0 }, { y: 0, opacity: 1, ease: Power4.easeInOut }, "end"); 

            // Create the Scene and trigger when visible
            var scene = new ScrollMagic.Scene({
              triggerElement: elem,
              reverse:false,
              offset: 0 /* offset the trigger Npx below scene's top */

            })

            .setTween(tl)
            .addTo(controller);

            // Add debug indicators fixed on right side
            //scene.addIndicators();

          });

        })();  


        (function openCloseMenu() {

          var html = $('html');
          var openTrigger = $('.menu-main-open');
          var closeTrigger = $('.menu-main-close');
          var menuLogo = $('.icon-logo');
          var menuContainer = $('.menu-main');
          var menuLeft = $('.menu-main-primary');
          var menuRight = $('.menu-main-right');
          var menu = $('.js-mobile-scroll-menu a');

          //TL
          var tlOpenMenu = new TimelineMax({paused: true});
          var tlCloseMenu = new TimelineMax({paused: true});

          //OPEN TIMELINE
          tlOpenMenu.set(html, {className: '+=is-main-menu-open'})
            .add("preOpen")
            .fromTo(menuLeft, 0.6, {
            y: 40, opacity: 0, visibility: 'hidden'
          }, {
            y: 0, opacity: 1, visibility: 'visible', ease: Power4.easeOut 
          }, "-=0.2")
          .fromTo(menuRight, 0.6, {
            y: -40, opacity: 0, visibility: 'hidden'
          }, {
            y: 0, opacity: 1, visibility: 'visible', ease: Power4.easeOut
          }, "-=0.5");

          //CLOSE TIMELINE
          tlCloseMenu.add("close")
            .to(menuLeft, 0.6, {
            y: 40, opacity: 0, ease: Power4.easeOut, onComplete: function() {
              menuLeft.css('visibility','hidden');
            }
          }, "close")
          .to(menuRight, 0.6, {
            y: -40, opacity: 0, ease: Power4.easeOut, onComplete: function() {
              menuRight.css('visibility','hidden');
            }
          }, "close").set(html, {className: '-=is-main-menu-open'}); 

          openTrigger.on('click', function(){ 
            if(tlOpenMenu.progress() < 1){
              tlOpenMenu.play();
                } else {
                    tlOpenMenu.restart();
                    console.log('menu opened');
                }
          });
                 
          closeTrigger.on('click', function(){
            if(tlCloseMenu.progress() < 1){
              tlCloseMenu.play();
                } else {
                    tlCloseMenu.restart();
                    console.log('menu closed');
                }
          });

          menu.on("click", function(){
            if ($('html').hasClass('is-main-menu-open')) {
                $('html').toggleClass('is-main-menu-open');
            }            

            var $this = $(this),
            href = $this.attr("href"),
            topY = $(href).offset().top;

            TweenLite.to($(window), 0, {
              scrollTo:{
                y: topY,
                autoKill: true
              },
              ease:Power3.easeOut
            });

            //return tlCloseMenu.play();
            return false;

          });

        })();  


        (function openCloseForm() {

          $('.js-toggle-form:not(.-inited)').click(function () {
            if ($('html').hasClass('is-main-menu-open')) {
                $('html').toggleClass('is-main-menu-open');
                $('html').toggleClass('is-form-open');
            } else {
                $('html').toggleClass('is-form-open');
            }
            setTimeout(function () {
              $('.c-form_wrap').animate({ scrollTop: 0 }, 0);
            }, 600);

            if (!$('html').hasClass('is-form-open')) {
                setTimeout(function () {
                    $('.c-form_wrap').animate({ scrollTop: 0 }, 0);
                }, 0);
            }

            return false;

          }).addClass("-inited");

        })(); 


        (function menuAccordion() {

          $(document).ready(function() {

            if($(window).width() < 801) {

              $('.js-accordion-trigger').bind('click', function(e){
                jQuery(this).parent().toggleClass('is-expanded');
                e.preventDefault();
              });              

            }

          });

        })();


        (function menuSlider() {

          $('.c-menu_outer').each(function() {

            var $slider = $(this);
            $slider.find('.panel:eq(0)').addClass('_active');
            $slider.find('.slide-link:eq(0)').addClass('active');
            var $activeSlide = $slider.find('.panel._active');
            var offset = $('.js-menus-list').offset().top;
            var tl = new TimelineMax();

            $('.slide-link').on('click', function() {

              TweenLite.to(window, 0.2,{scrollTo:{y:offset}});

              var $menus = $('.js-menus');
              var $wrapper = $('.js-menu-wrapper');
              var $copy = $('.js-menu-copy'); 
              var $menu_controller = $('.js-menus-list'); 
              var $accordion = $('.js-accordion');
              var $accordion_sub = $('.js-accordion-submenu'); 
              var $this = $(this);
              var offset_left = $this.offset();
              var initTabNum = $this.data('slide');
              var $panel = $('.panel');
              var $show = $('._active');
              
              function step_1() {
                $panel.removeClass('_active');
              }
              
              function step_2() {
                $copy.scrollTop(0);
                $('.num_' + initTabNum).addClass('_active');
              } 

              if(!tl.isActive()){
                tl.set($menu_controller, {
                  className: '-=is-expanded' 
                })
                .set($wrapper, {
                  className: '+=is-overflowing' 
                })
                .to($copy, 0.6, {
                  y: "140px",
                  opacity:0, 
                  ease: Power4.easeInOut,
                  onComplete: step_1
                }, '+=0.4')
                .fromTo($('.num_' + initTabNum), 0.4,  
                { 
                  opacity:0,
                  ease: Power2.easeInOut,
                  onComplete: step_2
                }, {
                  opacity:1,
                  ease: Power4.easeInOut, 
                  immediateRender:false
                })
                .to($copy, 0.6, {
                  y:0,
                  opacity:1, 
                  ease: Power4.easeInOut
                }, '+=0.4')
                .set($wrapper, {
                  className: '-=is-overflowing'
                })
                .to($accordion_sub, 0.1, {
                  opacity:1
                }, '+=0.2');  

                console.log('slide changed !');
                $('.slide-link').removeClass('active');
                var $activeLink = $('.slide-link[data-slide="' + $('.panel._active').data('slide') + '"]');
                $this.addClass('active');
              }

            }); 

          });

        })();


        (function initRoyalSlider() {

          $(".royalSlider").royalSlider({
            autoScaleSlider: true,
            autoScaleSliderWidth: 1024,
            autoScaleSliderHeight: 768,
            imageScaleMode: 'fill',
            keyboardNavEnabled: true,
            imageAlignCenter: false,
            slidesSpacing: 0,
            arrowsNavAutoHide: false,
            arrowsNavHideOnTouch: false,
            loop: true,
            transitionSpeed:800,
            easeOut: 'easeOutSine'
          });  

        })();


        (function initSelect() {

          $('.gfield_select').select2({
            minimumResultsForSearch: Infinity
          });      

        })();


        (function noBarbaAdmin() {

          $( ".ab-item" ).addClass( "no-barba" );

        })();


        (function siteIntroTransition() {

          var wrapper = $('#site-transition');
          var left_outer = $('.js-transition-left-outer');
          var left_inner = $('.js-transition-left-inner');
          var right_outer = $('.js-transition-right-outer');
          var right_inner = $('.js-transition-right-inner');
          var transitionTl = new TimelineMax({
            paused: true
          });
          
          transitionTl.fromTo(wrapper, .01, {
              display:"none", 
              opacity:0,                
              zIndex: 0
          }, {
              display:"block", 
              opacity:1,                
              zIndex: 1999
          }).fromTo(left_outer, 0.6, {
              xPercent: -100,
              autoAlpha: 1
          }, {
              xPercent: 0,
              ease: Power2.easeInOut
          }, 0).fromTo(left_inner, 0.6, {
              xPercent: 100,
              autoAlpha: 1
          }, {
              xPercent: 0,
              ease: Power2.easeInOut
          }, 0).fromTo(right_outer, 0.6, {
              xPercent: 100,
              autoAlpha: 1
          }, {
              xPercent: 0,
              ease: Power2.easeInOut
          }, 0).fromTo(right_inner, 0.6, {
              xPercent: -100,
              autoAlpha: 1
          }, {
              xPercent: 0,
              ease: Power2.easeInOut
          }, 0);


          var transitionIn = function(){
            transitionTl.play();
          };   


        })();


        gformInitDatepicker();        


        // var loadSite = function() {

        //   // number of loaded images for preloader progress
        //   var body = $('body');
        //   var loadedCount = 0; //current number of images loaded
        //   var imagesToLoad = $('.js-img-load').length; //number of slides with .bcg container
        //   var loadingProgress = 0; //timeline progress - starts at 0
           
        //   $('.barba-container').imagesLoaded({
        //       //background: true
        //   }).progress( function( instance, image ) { 
        //       loadProgress();
        //   });
           
        //   function loadProgress(imgLoad, image)
        //   {
        //     //one more image has been loaded
        //     loadedCount++;
         
        //     loadingProgress = (loadedCount/imagesToLoad);
         
        //     // GSAP tween of our progress bar timeline
        //     TweenLite.to(progressTl, 0.7, {progress:loadingProgress, ease:Linear.easeNone});
           
        //   }

        //   //progress timeline
        //   var progressTl = new TimelineMax({
        //       paused: true,
        //       onUpdate: progressUpdate,
        //       onComplete: loadComplete
        //   });
           
        //   progressTl
        //     //tween the progress bar width
        //     .set(body, {className: '+=is-loading'}).to($('.loader-progress'), 1, {css:{width:"100%"}, ease:Linear.easeNone});
           
        //   //as the progress bar width updates and grows we put the percentage loaded in the screen
        //   function progressUpdate() {
        //     //the percentage loaded based on the tween's progress
        //     loadingProgress = Math.round(progressTl.progress() * 100);
         
        //     //we put the percentage in the screen
        //     $(".loader-text").text(loadingProgress + '%');
           
        //   }

        //   function loadComplete() {
        //     // preloader out
        //     var wrapper = $('.loader-wrapper');
        //     var body = $('body');
        //     var html = $('html');
        //     var loader = $('#site-loader');
        //     var intro = $('#site-intro');
        //     var intro_left = $('.js-intro-left');
        //     var intro_right = $('.js-intro-right');
        //     var hero = $('.js-hero');            
        //     var logo = $('.js-logo');
        //     var title = $('.js-title');
        //     var hint = $('.js-scroll-hint');
        //     var preloaderOutTl = new TimelineMax();
         
        //     preloaderOutTl
        //       .to(wrapper, 0.3, {autoAlpha: 0, ease:Back.easeInOut})
        //       .set(html, {className: '+=is-intro-enter'})
        //       .set(body, {className: '-=is-loading'})
        //       .set(html, {className: '+=is-intro-leave'})
        //       .to(loader, 0.8, {yPercent: 100, ease:Power4.easeInOut})
        //       .set(loader, {className: '+=is-hidden'})
        //       .set(html, {className: '+=is-intro-hide'})
        //       .set(intro, {className: '+=is-hidden'})
        //       .staggerFromTo(title, 0.8, {autoAlpha: 0}, {autoAlpha:1, ease:Power1.easeOut}, 0.8, '+=1.2')
        //       .staggerFromTo(logo, 0.8, {autoAlpha: 0}, {autoAlpha:1, ease:Power1.easeOut}, 0.8)
        //       .from(hint, 0.3, {y: -20, autoAlpha: 0, ease:Power1.easeOut}, '+=0.1')
        //       .set(hero, {className: '+=is-loaded'});
         
        //     return preloaderOutTl;
        //   }

        // }; 


        // var scrollReveal = function() {

        //   $('[data-scrollreveal]').each(function (index, elem) {
        //     // Init ScrollMagic Controller
        //     var controller = new ScrollMagic.Controller();
             
        //     // Create Animations
        //     var img = $(elem).find('.js-feature-image'),
        //         largeImg = $(elem).find('.js-feature-large-image'),
        //         caption = $(elem).find('.js-feature-caption'),
        //         title = $(elem).find('.js-feature-header'),
        //         hr = $(elem).find('hr'),
        //         text = $(elem).find('.js-feature-text'),
        //         slider = $(elem).find('.js-feature-slider'),
        //         gallery = $(elem).find('.js-feature-gallery'),
        //         btn = $(elem).find('.js-feature-link');
            
        //     var tl = new TimelineMax({pause: true}); 

        //     tl.add("start") // add timeline label
        //       .fromTo(title, 0.6, { x: '-140px', opacity: 0 }, { x: 0, opacity: 1, ease: Power4.easeInOut }, "end")
        //       .fromTo(hr, 0.6, { x: '140px', opacity: 0 }, { x: 0, opacity: 1, ease: Power2.easeInOut }, "end")
        //       .fromTo(text, 0.6, { x: '140px', opacity: 0 }, { x: 0, opacity: 1, ease: Power4.easeInOut }, "end")
        //       .fromTo(btn, 0.6, { x: '-140px', opacity: 0 }, { x: 0, opacity: 1, ease: Power4.easeInOut }, "end")
        //       .fromTo(img, 0.6, { y: '140px', opacity: 0 }, { y: 0, opacity: 1, ease: Power2.easeInOut }, "end")
        //       .fromTo(gallery, 0.6, { y: '240px', opacity: 0 }, { y: 0, opacity: 1, ease: Power2.easeInOut }, "end")
        //       .fromTo(largeImg, 0.6, { y: '240px', opacity: 0 }, { y: 0, opacity: 1, ease: Power2.easeInOut }, "end")
        //       .staggerFromTo(slider, 0.6, { y: '140px', opacity: 0 }, { y: 0, opacity: 1, ease: Power2.easeInOut }, "end")
        //       .staggerFromTo(caption, 0.6, { y: '-140px', opacity: 0 }, { y: 0, opacity: 1, ease: Power4.easeInOut }, "end"); 

        //     // Create the Scene and trigger when visible
        //     var scene = new ScrollMagic.Scene({
        //       triggerElement: elem,
        //       reverse:false,
        //       offset: 0 /* offset the trigger Npx below scene's top */

        //     })

        //     .setTween(tl)
        //     .addTo(controller);

        //     // Add debug indicators fixed on right side
        //     //scene.addIndicators();

        //   });

        // };


        //$( ".ab-item" ).addClass( "no-barba" );


        // var killStates = function() {

        //   console.log('Main Menu Removed');
        //   $('html').removeClass('is-main-menu-open');

        //   console.log('Loading State Removed');
        //   $('body').removeClass('is-loading');

        // }; 


        // var openCloseMenu = function() {

        //   var html = $('html');
        //   var openTrigger = $('.menu-main-open');
        //   var closeTrigger = $('.menu-main-close');
        //   var menuLogo = $('.icon-logo');
        //   var menuContainer = $('.menu-main');
        //   var menuLeft = $('.menu-main-primary');
        //   var menuRight = $('.menu-main-right');
        //   //var menuItem = $( '.js-mobile-scroll-menu a');
        //   var $menu = $(".js-mobile-scroll-menu");

        //   //TL
        //   var tlOpenMenu = new TimelineMax({paused: true});
        //   var tlCloseMenu = new TimelineMax({paused: true});

        //   //OPEN TIMELINE
        //   tlOpenMenu.set(html, {className: '+=is-main-menu-open'})
        //     .add("preOpen")
        //     .fromTo(menuLeft, 0.6, {
        //     y: 40, opacity: 0, visibility: 'hidden'
        //   }, {
        //     y: 0, opacity: 1, visibility: 'visible', ease: Power4.easeOut
        //   }, "-=0.2")
        //   .fromTo(menuRight, 0.6, {
        //     y: -40, opacity: 0, visibility: 'hidden'
        //   }, {
        //     y: 0, opacity: 1, visibility: 'visible', ease: Power4.easeOut
        //   }, "-=0.5");

        //   //CLOSE TIMELINE
        //   tlCloseMenu.add("close")
        //     .to(menuLeft, 0.6, {
        //     y: 40, opacity: 0, ease: Power4.easeOut, onComplete: function() {
        //       menuLeft.css('visibility','hidden');
        //     }
        //   }, "close")
        //   .to(menuRight, 0.6, {
        //     y: -40, opacity: 0, ease: Power4.easeOut, onComplete: function() {
        //       menuRight.css('visibility','hidden');
        //     }
        //   }, "close").set(html, {className: '-=is-main-menu-open'}); 

        //   //EVENTS
        //   openTrigger.on('click', function(){ 
        //     if(tlOpenMenu.progress() < 1){
        //       tlOpenMenu.play();
        //         } else {
        //             tlOpenMenu.restart();
        //             console.log('menu opened');
        //         }
        //   });
                 
        //   closeTrigger.on('click', function(){
        //     if(tlCloseMenu.progress() < 1){
        //       tlCloseMenu.play();
        //         } else {
        //             tlCloseMenu.restart();
        //             console.log('menu closed');
        //         }
        //   });

        //   $menu.on("click","a", function(){

        //     var $this = $(this),
        //     href = $this.attr("href"),
        //     topY = $(href).offset().top;

        //     TweenLite.to($(window), 0, {
        //       scrollTo:{
        //         y: topY,
        //         autoKill: true
        //       },
        //       ease:Power3.easeOut
        //     });

        //     tlCloseMenu.play();
        //     return false;

        //   });


        //   // menuItem.on('click', function(){
        //   //   tlCloseMenu.play();
        //   // });

        // };

        // var openCloseForm = function() {

        //   $('.js-toggle-form:not(.-inited)').click(function () {
        //     if ($('html').hasClass('is-main-menu-open')) {
        //         $('html').toggleClass('is-main-menu-open');
        //         $('html').toggleClass('is-form-open');
        //     } else {
        //         $('html').toggleClass('is-form-open');
        //     }
        //     setTimeout(function () {
        //       $('.c-form_wrap').animate({ scrollTop: 0 }, 0);
        //     }, 600);

        //     if (!$('html').hasClass('is-form-open')) {
        //         setTimeout(function () {
        //             $('.c-form_wrap').animate({ scrollTop: 0 }, 0);
        //         }, 0);
        //     }

        //     return false;

        //   }).addClass("-inited");

        // };


        // var menuAccordion = function() {

        //   $(document).ready(function() {

        //     // $('.js-accordion-trigger').bind('click', function(e){
        //     //   e.preventDefault();
        //     // });              


        //     if($(window).width() < 801) {

        //       $('.js-accordion-trigger').bind('click', function(e){
        //         jQuery(this).parent().toggleClass('is-expanded');
        //         e.preventDefault();
        //       });              

        //     }

        //   });

        // };


        // var menuSlider = function() { 

        //   $('.c-menu_outer').each(function() {

        //     var $slider = $(this);
        //     $slider.find('.panel:eq(0)').addClass('_active');
        //     $slider.find('.slide-link:eq(0)').addClass('active');
        //     var $activeSlide = $slider.find('.panel._active');
        //     var offset = $('.js-menus-list').offset().top;
        //     var tl = new TimelineMax();

        //     $('.slide-link').on('click', function() {

        //       TweenLite.to(window, 0.2,{scrollTo:{y:offset}});

        //       var $menus = $('.js-menus');
        //       var $wrapper = $('.js-menu-wrapper');
        //       var $copy = $('.js-menu-copy'); 
        //       //var $img = $('.js-menu-image');
        //       //var $copy_mask = $('.js-menu-copy-mask');  
        //       //var $img_mask = $('.js-menu-image-mask');  
        //       var $menu_controller = $('.js-menus-list'); 
        //       var $accordion = $('.js-accordion');
        //       var $accordion_sub = $('.js-accordion-submenu'); 
        //       var $this = $(this);
        //       var offset_left = $this.offset();
        //       var initTabNum = $this.data('slide');
        //       var $panel = $('.panel');
        //       var $show = $('._active');
              

        //       function step_1() {
        //         //TweenLite.to(window, 0.1,{scrollTo:{y:offset}});
        //         $panel.removeClass('_active');

        //         //$this.scrollTop(0);
        //         //TweenLite.to($menus, 0.1, {scrollTo:{y:0}, ease:Power2.easeInOut});
        //       }
              
        //       function step_2() {
        //         $copy.scrollTop(0);
        //         $('.num_' + initTabNum).addClass('_active');
        //       } 

        //       if(!tl.isActive()){
        //         tl.set($menu_controller, {
        //           className: '-=is-expanded' 
        //         })
        //         .set($wrapper, {
        //           className: '+=is-overflowing' 
        //         })
        //         .to($copy, 0.6, {
        //           y: "140px",
        //           opacity:0, 
        //           ease: Power4.easeInOut,
        //           onComplete: step_1
        //         }, '+=0.4')
        //         // .to($img, 0.6, { 
        //         //   x: "-140px",
        //         //   opacity:0, 
        //         //   ease: Power4.easeInOut, 
        //         //   onComplete: step_1
        //         // }, '-=0.6')
        //         .fromTo($('.num_' + initTabNum), 0.4,  
        //         { 
        //           opacity:0,
        //           ease: Power2.easeInOut,
        //           onComplete: step_2
        //         }, {
        //           opacity:1,
        //           ease: Power4.easeInOut, 
        //           immediateRender:false
        //         })
        //         .to($copy, 0.6, {
        //           y:0,
        //           opacity:1, 
        //           ease: Power4.easeInOut
        //         }, '+=0.4')
        //         // .to($img, 0.6, {
        //         //   x:0,
        //         //   opacity:1, 
        //         //   ease: Power4.easeInOut
        //         // }, '-=0.6')
        //         .set($wrapper, {
        //           className: '-=is-overflowing'
        //         })
        //         .to($accordion_sub, 0.1, {
        //           opacity:1
        //         }, '+=0.2');  

        //         console.log('slide changed !');
        //         $('.slide-link').removeClass('active');
        //         var $activeLink = $('.slide-link[data-slide="' + $('.panel._active').data('slide') + '"]');
        //         $this.addClass('active');
        //       }

        //     }); 

        //   });

        // };


        // var initSelect = function() { 

        //   $('.gfield_select').select2({
        //     minimumResultsForSearch: Infinity
        //   });      

        // };  


        // var initRoyalSlider = function() { 

        //   $(".royalSlider").royalSlider({
        //     autoScaleSlider: true,
        //     autoScaleSliderWidth: 1024,
        //     autoScaleSliderHeight: 768,
        //     imageScaleMode: 'fill',
        //     keyboardNavEnabled: true,
        //     imageAlignCenter: false,
        //     slidesSpacing: 0,
        //     arrowsNavAutoHide: false,
        //     arrowsNavHideOnTouch: false,
        //     loop: true,
        //     transitionSpeed:800,
        //     easeOut: 'easeOutSine'
        //   });  

        // }; 


        // var wrapper = $('#site-transition');
        // var left_outer = $('.js-transition-left-outer');
        // var left_inner = $('.js-transition-left-inner');
        // var right_outer = $('.js-transition-right-outer');
        // var right_inner = $('.js-transition-right-inner');
        // var transitionTl = new TimelineMax({
        //   paused: true
        // });
        
        // transitionTl.fromTo(wrapper, .01, {
        //     display:"none", 
        //     opacity:0,                
        //     zIndex: 0
        // }, {
        //     display:"block", 
        //     opacity:1,                
        //     zIndex: 1999
        // }).fromTo(left_outer, 0.6, {
        //     xPercent: -100,
        //     autoAlpha: 1
        // }, {
        //     xPercent: 0,
        //     ease: Power2.easeInOut
        // }, 0).fromTo(left_inner, 0.6, {
        //     xPercent: 100,
        //     autoAlpha: 1
        // }, {
        //     xPercent: 0,
        //     ease: Power2.easeInOut
        // }, 0).fromTo(right_outer, 0.6, {
        //     xPercent: 100,
        //     autoAlpha: 1
        // }, {
        //     xPercent: 0,
        //     ease: Power2.easeInOut
        // }, 0).fromTo(right_inner, 0.6, {
        //     xPercent: -100,
        //     autoAlpha: 1
        // }, {
        //     xPercent: 0,
        //     ease: Power2.easeInOut
        // }, 0);


        // var transitionIn = function(){
        //   transitionTl.play();
        // };
        
        // var transitionOut = function(){
        //   transitionTl.reverse();
        // };


        // var FadeTransition = Barba.BaseTransition.extend({
        //   start: function() {
        //     /**
        //      * This function is automatically called as soon the Transition starts
        //      * this.newContainerLoading is a Promise for the loading of the new container
        //      * (Barba.js also comes with an handy Promise polyfill!)
        //      */


        //     // As soon the loading is finished and the old page is faded out, let's fade the new page
        //     Promise
        //       .all([this.newContainerLoading, this.showPreloader()])
        //       .then(this.hidePreloader.bind(this));
        //   },

        //   showPreloader: function() {
        //     /**
        //      * this.oldContainer is the HTMLElement of the old Container
        //      */


        //     //transitionIn();

        //     return transitionIn(),
        //     $(this.oldContainer).animate({ opacity: 0 }).promise();

        //   },

        //   hidePreloader: function() {
        //     /**
        //      * this.newContainer is the HTMLElement of the new Container
        //      * At this stage newContainer is on the DOM (inside our #barba-container and with visibility: hidden)
        //      * Please note, newContainer is available just after newContainerLoading is resolved!
        //      */
        //     //var transitionOut = transitionTl.reverse();
        //     var _this = this;
        //     var $el = $(this.newContainer);

        //     $(this.oldContainer).hide();

        //     $el.css({
        //       visibility : 'visible',
        //       opacity : 0
        //     });

        //     document.body.scrollTop = 0; 
            

        //     $el.animate({ opacity: 1 }, 2000, function() {

             
        //       /**
        //        * Do not forget to call .done() as soon your transition is finished!
        //        * .done() will automatically remove from the DOM the old Container
        //        */
               
        //       _this.done();
        //       transitionOut();

                       
        //     });
        //   }
        // });

        // Barba.Pjax.getTransition = function() {
        //   /**
        //    * Here you can use your own logic!
        //    * For example you can use different Transition based on the current page or link...
        //    */

        //   return FadeTransition;
        // };

        // var Homepage = Barba.BaseView.extend({
        //   namespace: 'page-front-page.php',
        //   onEnter: function() {
        //     // The new Container is ready and attached to the DOM.
        //     //loadSite();
        //     //scrollReveal();
        //     //openCloseMenu();
        //     //openCloseForm();
        //     //menuSlider();
        //   },
        //   onEnterCompleted: function() {
        //     // The Transition has just finished.
        //     //menuAccordion();  
        //     //menuSlider();
        //     //initRoyalSlider();
        //     //gformInitDatepicker();  
        //     //initSelect();
        //     //killStates();
        //   },
        //   onLeave: function() {
        //     // A new Transition toward a new page has just started.
        //   },
        //   onLeaveCompleted: function() {
        //     // The Container has just been removed from the DOM.
        //     //killStates();
        //   }
        // });

        // // Don't forget to init the view!
        // Homepage.init();

        // var Menupage = Barba.BaseView.extend({
        //   namespace: 'page-menus-page.php',
        //   onEnter: function() {
        //     // The new Container is ready and attached to the DOM.
        //     //loadSite();
        //     //openCloseMenu();
        //     //openCloseForm();
        //   },
        //   onEnterCompleted: function() {
        //     // The Transition has just finished.
        //     //pageIntro();
        //     //resizeHero();  
        //     //menuAccordion();  
        //     //menuSlider();
        //     //gformInitDatepicker();  
        //     //initSelect();

        //   },
        //   onLeave: function() {
        //     // A new Transition toward a new page has just started.

        //   },
        //   onLeaveCompleted: function() {
        //     // The Container has just been removed from the DOM.
        //     //killStates();
        //   }
        // });

        // // Don't forget to init the view!
        // Menupage.init(); 

        // var Contactpage = Barba.BaseView.extend({
        //   namespace: 'page-contact-page.php',
        //   onEnter: function() {
        //     // The new Container is ready and attached to the DOM.
        //     //loadSite();
        //     //openCloseMenu();
        //     //openCloseForm();
        //   },
        //   onEnterCompleted: function() {
        //     //gformInitDatepicker();  
        //     //initSelect();

        //   },
        //   onLeave: function() {
        //     // A new Transition toward a new page has just started.
        //   },
        //   onLeaveCompleted: function() {
        //     // The Container has just been removed from the DOM.
        //     //killStates();
        //   }
        // });

        // // Don't forget to init the view!
        // Contactpage.init();   


        // var Bookingpage = Barba.BaseView.extend({
        //   namespace: 'page-booking-page.php',
        //   onEnter: function() {
        //     // The new Container is ready and attached to the DOM.
        //     //loadSite();
        //     //openCloseMenu();
        //     //openCloseForm();
        //   },
        //   onEnterCompleted: function() {
        //     // The Transition has just finished.
        //     //gformInitDatepicker();  
        //     //initSelect();

        //   },
        //   onLeave: function() {
        //     // A new Transition toward a new page has just started.
        //   },
        //   onLeaveCompleted: function() {
        //     // The Container has just been removed from the DOM.
        //     //killStates();
        //   }
        // });

        // // Don't forget to init the view!
        // Bookingpage.init();  


        // var Reviewspage = Barba.BaseView.extend({
        //   namespace: 'page-reviews-page.php',
        //   onEnter: function() {
        //     // The new Container is ready and attached to the DOM.
        //     //loadSite();
        //     //openCloseMenu();
        //     //openCloseForm();
        //   },
        //   onEnterCompleted: function() {
        //     //gformInitDatepicker();  
        //     //initSelect();
        //   },
        //   onLeave: function() {
        //     // A new Transition toward a new page has just started.
        //   },
        //   onLeaveCompleted: function() {
        //     // The Container has just been removed from the DOM.
        //     //killStates();
        //   }
        // });

        // // Don't forget to init the view!
        // Reviewspage.init();  

        // var Menusingle = Barba.BaseView.extend({
        //   namespace: 'single-menu',
        //   onEnter: function() {
        //     // The new Container is ready and attached to the DOM.
        //     //loadSite();
        //     //openCloseMenu();
        //     //openCloseForm();
        //   },
        //   onEnterCompleted: function() {
        //     //gformInitDatepicker();  
        //     //initSelect();
        //   },
        //   onLeave: function() {
        //     // A new Transition toward a new page has just started.
        //   },
        //   onLeaveCompleted: function() {
        //     // The Container has just been removed from the DOM.
        //     //killStates();
        //   }
        // });

        // // Don't forget to init the view!
        // Menusingle.init();  

        // var Thankyoupage = Barba.BaseView.extend({
        //   namespace: 'page-thankyou-page.php',
        //   onEnter: function() {
        //     // The new Container is ready and attached to the DOM.
        //     //loadSite();
        //     //openCloseMenu();
        //     //openCloseForm();
        //   },
        //   onEnterCompleted: function() {
        //     // The Transition has just finished.
        //   },
        //   onLeave: function() {
        //     // A new Transition toward a new page has just started.
        //   },
        //   onLeaveCompleted: function() {
        //     // The Container has just been removed from the DOM.
        //     //killStates();
        //   }
        // });

        // // Don't forget to init the view!
        // Thankyoupage.init();                 

        // Barba.Pjax.start();

      },
      finalize: function() { 
        // JavaScript to be fired on all pages, after page specific JS is fired


      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The rInOutg fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
